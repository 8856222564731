import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useRevalidator,
} from '@remix-run/react';
import { format } from 'date-fns';

import { type DtoPromotionHistory } from '@lp-lib/api-service-client/public';

import { type Action, ActionSheet } from '../components/ActionSheet';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../components/ConfirmCancelModalContext';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { AdminView } from '../pages/Admin/AdminView';
import { apiService } from '../services/api-service';
import { OrganizerUtils } from '../types';
import { fromDTOOrganizer } from '../utils/api-dto';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const resp = await tokenWithRedirect(
    () => apiService.promotion.getHistories(),
    action.request.url,
    { admin: true }
  );

  return {
    histories: resp.data.histories,
  };
};

function PromotionHistoryActionSheet(props: {
  history: DtoPromotionHistory;
  onDelete: () => void;
}): JSX.Element {
  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: () => {
        props.onDelete();
      },
    },
  ];

  return <ActionSheet actions={actions} />;
}

function PromotionHistoriesList(props: {
  histories: DtoPromotionHistory[];
  onRefresh: () => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleDelete = async (history: DtoPromotionHistory) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <ConfirmCancelModalHeading>
          Are you sure you want to delete the history?
        </ConfirmCancelModalHeading>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await apiService.promotion.deleteHistory(history.id);
    props.onRefresh();
  };

  return (
    <div className='text-white w-full h-full p-12'>
      <header className='flex justify-between items-center'>
        <h1 className='font-bold text-4xl'>Promotion Histories</h1>
      </header>

      <main className='w-full mt-7'>
        <table className='mt-2 w-full'>
          <thead>
            <tr className='text-left h-12 text-base text-bold'>
              <th className='pl-2.5'>Organization</th>
              <th>Recipient</th>
              <th>Medium</th>
              <th>Campaign</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.histories?.map((history) => (
              <tr
                key={history.id}
                className='w-full h-10 text-sms cursor-pointer hover:bg-lp-gray-002'
              >
                <td className='pl-2.5'>
                  <p className='max-w-40 truncate'>
                    {history.organization?.name}
                  </p>
                </td>
                <td>
                  {OrganizerUtils.GetDisplayName(
                    fromDTOOrganizer(history.recipient)
                  )}
                </td>
                <td>{history.medium}</td>
                <td>{history.campaign}</td>

                <td>
                  {format(new Date(history.createdAt), 'MM/dd/yy h:mm aa')}
                </td>

                <td>
                  <div className='flex flex-row-reverse items-center pr-1.5'>
                    <PromotionHistoryActionSheet
                      history={history}
                      onDelete={() => handleDelete(history)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {props.histories.length === 0 && (
          <div className='w-full mt-30 flex items-center justify-center text-secondary'>
            No Histories.
          </div>
        )}
      </main>
    </div>
  );
}

export function Component() {
  const { histories } = useLoaderData<typeof clientLoader>();
  const revalidator = useRevalidator();

  return (
    <AdminView className='bg-library-2023-07'>
      <PromotionHistoriesList
        histories={histories}
        onRefresh={() => revalidator.revalidate()}
      />
    </AdminView>
  );
}
